/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as React from 'react';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SEO } from '../components/seo';

const BlogPage = ({ data }) =>
{
  const { t } = useTranslation();
  const { group: categories } = data.allMdx;

  const categoriesNames = {
    article: t('Articles'),
    book: t('Books'),
  };

  return (
    <>
      <SEO
        title={t('Elevating Your Mental Wellness')}
        description={t('Discover practical tips, insights, and strategies for cultivating a positive and balanced mindset on our "Elevating Your Mental Wellness" blog. Explore the latest research and expert advice on everything from mindfulness and meditation to stress reduction and self-care.')}
      ></SEO>
      <Layout>
        <div className="py-20 sm:py-36 clip-path-curved bg-slate-900">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl"><Trans>Unwind your mind</Trans></h2>
            <p className="hidden md:block mt-3 max-w-2xl mx-auto text-xl text-gray-400 sm:mt-4">
              <Trans>Discover practical tips, insights, and strategies for cultivating a positive and balanced mindset.</Trans>
            </p>
          </div>
        </div>
        <div className="relative bg-white mb-20 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">

          <div className="relative max-w-7xl mx-auto">

            {
              categories.map((category) =>
              {
                return <div className='mt-12'>
                  <h3 className='text-3xl text-slate-800 font-extrabold'>{categoriesNames[category.name]}</h3>
                  <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    {category.edges
                      .filter(post => post.node.frontmatter.title.length > 0)
                      .map(({ node: post }) => (
                        <div key={post.id}>
                          <Link to={`/blog/${post.frontmatter.slug}`}>
                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                              <div className="flex-shrink-0">
                                <GatsbyImage className="h-48 w-full object-cover" image={getImage(post.frontmatter.featuredImage)} alt="Featured image"></GatsbyImage>
                              </div>
                              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                <div className="flex-1">
                                  <div className="block mt-2">
                                    <p className="text-xl font-semibold text-gray-900">{post.frontmatter.title}</p>
                                    <p className="mt-3 text-base text-gray-500">{post.frontmatter.summary}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              })
            }

          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allMdx(filter: {frontmatter: { language: {eq: $language}}}) {
      group(field: frontmatter___category){
        name: fieldValue
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            category
            slug
            author
            title
            summary
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
    }
  }

`
